import React from "react";
import PropTypes from "prop-types";

import Header from "./header/header";
import Footer from "./footer/footer";

import styles from "./layout.module.scss";
import { isElectionOver } from "../../utils/calendar";

const Layout = ({ children }) => (
  <div className={styles.wrapper}>
    <Header />
    <main className={`bp-fill-height ${styles.main} ${isElectionOver() ? styles.noCalendar : ""}`}>
      {children}
    </main>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
