import YES_NO from "../../enums/yes-no.json";

import { ADULT_DATE } from "./calendar";

const isAdultAtElectionStart = birthDate => {
  const yearDifference = ADULT_DATE.getFullYear() - birthDate.getFullYear();
  if (yearDifference > 18) {
    return YES_NO.YES;
  }
  if (yearDifference < 18) {
    return YES_NO.NO;
  }
  const adultMonth = ADULT_DATE.getMonth();
  const birthMonth = birthDate.getMonth();
  if (adultMonth > birthMonth) {
    return YES_NO.YES;
  }
  if (adultMonth < birthMonth) {
    return YES_NO.NO;
  }
  const adultDay = ADULT_DATE.getDate();
  const birthDay = birthDate.getDate();
  return adultDay >= birthDay ? YES_NO.YES : YES_NO.NO;
};

const formatAvailabilityEntry = ({ from, to }) => {
  const dateTime = new Date(from || to);
  dateTime.setHours(0, 0, 0, 0);

  return {
    dateTime,
    dateString: dateTime.toLocaleDateString(),
    fromTime: from,
    fromTimeString: from ? from.toLocaleTimeString().substr(0, 5) : "",
    toTime: to,
    toTimeString: to ? to.toLocaleTimeString().substr(0, 5) : ""
  };
};

const formatComment = comment => ({
  ...comment,
  createdAtString: comment.createdAt.toLocaleString()
});

export const mapUserData = data => {
  const {
    trainingCompleted = YES_NO.NO,
    licenceOrdered = YES_NO.NO,
    hasLicence = YES_NO.NO,
    scheduleVoteCount = "DONT_KNOW",
    phone,
    birthDate,
    createdAt,
    updatedAt,
    schedulesUpdatedAt,
    availability,
    comments,
    licenceCollectionMethod,
    secondPhaseConfirmed,
    secondPhase
  } = data;

  const secondPhaseOnly = secondPhaseConfirmed === undefined;

  return {
    ...data,
    phoneNoCode: phone.replace(/\+3706/, ""),
    birthDateString: birthDate.toLocaleDateString(),
    createdAtString: createdAt.toLocaleString(),
    updatedAtString: updatedAt ? updatedAt.toLocaleString() : "",
    // TODO Temp fix
    scheduleRevised:
      !!schedulesUpdatedAt || (secondPhaseOnly && availability && availability.length > 0),
    secondPhase: secondPhaseOnly ? YES_NO.YES : secondPhase,
    secondPhaseOnly,
    availability: availability ? availability.map(formatAvailabilityEntry) : [],
    comments: comments ? comments.map(formatComment) : [],
    additionalInfoAvailable: licenceCollectionMethod ? YES_NO.YES : YES_NO.NO,
    adult: isAdultAtElectionStart(birthDate),
    trainingCompleted,
    licenceOrdered,
    hasLicence,
    scheduleVoteCount,
    raw: data
  };
};
