import React from "react";
import PropTypes from "prop-types";

import { SOCIAL } from "../../content/social";

import styles from "./social-links.module.scss";

const SocialLink = ({ url, label, Icon, className }) => (
  <a
    href={url}
    aria-label={label}
    target="_blank"
    rel="noreferrer noopener nofollow"
    className={`bp-link ${className}`}
  >
    <Icon className="bp-stroke" />
  </a>
);

SocialLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  className: PropTypes.string
};

SocialLink.defaultProps = {
  className: ""
};

const SocialLinks = ({ className, linkClassName }) => (
  <p className={`${styles.container} ${className}`}>
    {SOCIAL.map(({ key, url, label, Icon }) => (
      <SocialLink key={key} url={url} label={label} Icon={Icon} className={linkClassName} />
    ))}
  </p>
);

SocialLinks.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string
};

SocialLinks.defaultProps = {
  className: "",
  linkClassName: ""
};

export default SocialLinks;
