import { ADULT_DATE as ADULT_DATE_STRING, ELECTION_STAGES } from "../../content/calendar";

import { DAY_DURATION } from "./time";
import { getRemainingTime } from "./time-breakdown";

const ELECTION_DAY = new Date(ELECTION_STAGES[ELECTION_STAGES.length - 1].date);

const getElectionDateRange = () => {
  const FIRST_ELECTION_DATE = new Date(ELECTION_STAGES[0].start);
  const LAST_ELECTION_DATE = new Date(ELECTION_STAGES[ELECTION_STAGES.length - 1].date);

  const range = [];
  let date = FIRST_ELECTION_DATE;
  while (date < LAST_ELECTION_DATE) {
    range.push(date);
    date = new Date(date.valueOf() + DAY_DURATION * 1000);
  }
  range.push(LAST_ELECTION_DATE);
  return range;
};

const ELECTION_DATE_RANGE = getElectionDateRange();

const getStagesByDate = () => {
  const stagesByDate = {};

  ELECTION_DATE_RANGE.forEach(electionDate => {
    stagesByDate[electionDate.valueOf()] = ELECTION_STAGES.filter(({ start, end, date }) => {
      if (date) {
        return electionDate.valueOf() === new Date(date).valueOf();
      }
      const range = {
        start: new Date(start),
        end: new Date(end)
      };

      return electionDate >= range.start && electionDate <= range.end;
    });
  });

  return stagesByDate;
};

const STAGES_BY_DATE = getStagesByDate();

export { ELECTION_DAY, ELECTION_DATE_RANGE, STAGES_BY_DATE };

export const ADULT_DATE = new Date(ADULT_DATE_STRING);

export const filterElectionStagesByDates = dates =>
  ELECTION_STAGES.filter(({ start, end, date }) => {
    const stageStartDate = new Date(date || start);
    const stageEndDate = new Date(date || end);
    stageEndDate.setDate(stageEndDate.getDate() + 1);
    return dates.some(userDate => userDate >= stageStartDate && userDate < stageEndDate);
  });

export const getTimeUntilElectionDay = () => getRemainingTime(ELECTION_DAY);

export const isElectionOver = () => {
  const dayAfterElection = new Date(ELECTION_DAY);
  dayAfterElection.setDate(dayAfterElection.getDate() + 1);
  return Date.now() >= dayAfterElection;
};

export const isTodayElectionDay = () => Date.now() >= ELECTION_DAY && !isElectionOver();
