import React, { useState, useEffect, useMemo } from "react";
import { useWindowWidth } from "@react-hook/window-size";

import { BREAKPOINTS } from "../../../global-styles/breakpoints";
import logo from "../../../assets/images/logo.svg";
import logoNoText from "../../../assets/images/logo-no-text.svg";
import * as Storage from "../../../storage";
import { OutsideSSR } from "../../../ssr-context";
import { isElectionOver } from "../../../utils/calendar";

import LogoLink from "../../logo-link";
import DonateButton from "../../donate-button";

import Calendar from "./calendar";
import HeaderNavigation from "./header-navigation";
import HeaderNavigationMobile from "./header-navigation-mobile";
import ProfilePopup from "./profile-popup";

import styles from "./header.module.scss";

const CALENDAR_HIDDEN_STORAGE_KEY = "calendar-hidden";

const Header = () => {
  const [isCalendarVisible, setCalendarVisible] = useState(false);

  const toggleCalendar = () => {
    const nextValue = !isCalendarVisible;
    setCalendarVisible(nextValue);
    Storage.store(CALENDAR_HIDDEN_STORAGE_KEY, !nextValue);
  };

  const windowWidth = useWindowWidth();

  const isMobile = useMemo(() => windowWidth < BREAKPOINTS.SM, [windowWidth]);

  const isLargeScreen = useMemo(() => windowWidth >= BREAKPOINTS.LG, [windowWidth]);

  const logoSrc = useMemo(() => (isMobile ? logoNoText : logo), [isMobile]);

  useEffect(() => {
    const isCalendarHiddenInitially = Storage.get(CALENDAR_HIDDEN_STORAGE_KEY);
    setCalendarVisible(!isCalendarHiddenInitially && isLargeScreen);
  }, []);

  const isElectionInProgress = !isElectionOver();

  return (
    <>
      {isElectionInProgress ? (
        <aside
          id="calendar"
          className={`${styles.calendarAside} ${isCalendarVisible && styles.visible}`}
          aria-hidden={!isCalendarVisible}
        >
          <Calendar
            className={styles.calendar}
            hidden={!isCalendarVisible}
            onCalendarToggle={toggleCalendar}
          />
        </aside>
      ) : null}

      <header
        className={`bp-section-alternative ${styles.header} ${
          isElectionInProgress ? "" : styles.noCalendar
        }`}
      >
        <div className="bp-container bp-between">
          <div className={`bp-between ${styles.logoAndDonate}`}>
            <LogoLink>
              {/*
                This is necessary because Gatsby does not detect a change
                in window size once a page loads, and displays whatever
                logo was used during SSR, regardless of window size.
              */}
              {typeof window === "undefined" ? (
                <img src={logoNoText} className={styles.logo} height="48" alt="" />
              ) : (
                <span>
                  {/* Using a span is crucial here */}
                  <img src={logoSrc} className={styles.logo} height="48" alt="" />
                </span>
              )}
            </LogoLink>

            <DonateButton className={styles.donateButton} />
          </div>

          <HeaderNavigation
            isCalendarVisible={isCalendarVisible}
            onCalendarToggle={toggleCalendar}
            className={styles.navigation}
          />

          <div className="bp-between">
            <OutsideSSR>
              <ProfilePopup />
            </OutsideSSR>

            <HeaderNavigationMobile
              isCalendarVisible={isCalendarVisible}
              onCalendarToggle={toggleCalendar}
              className={styles.navigationMobile}
            />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
