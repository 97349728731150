import React from "react";
import PropTypes from "prop-types";

import NAVIGATION from "../../../../content/navigation.json";
import CONTACT from "../../../../content/contact.json";

import logoImg from "../../../assets/images/logo.svg";

import ButtonLink from "../../button-link";
import SocialLinks from "../../social-links";
import DonateButton from "../../donate-button";

import styles from "./footer.module.scss";
import { useTranslations } from "../../../hooks/translations";
import LocalizedLink from "../../localized-link";

const NavigationLink = ({ url, label }) => (
  <ButtonLink to={url} className={`bp-navigation ${styles.navLink}`}>
    {label}
  </ButtonLink>
);

NavigationLink.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

const NavigationColumn = ({ label, links }) => (
  <div className={styles.navColumn}>
    <h3>{label}</h3>
    <ul className={styles.navList}>
      {links.map(link => (
        <li key={link.key}>
          <NavigationLink url={link.url} label={link.label} />
        </li>
      ))}
    </ul>
  </div>
);

NavigationColumn.propTypes = {
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.any).isRequired
};

const Footer = () => {
  const t = useTranslations("navigation");

  const { companyTitle, companyName, companyCode } = useTranslations("contacts");

  const currentYear = new Date().getFullYear();

  return (
    <footer className={`bp-section-alternative ${styles.footer}`}>
      <div className="bp-container">
        <div className={styles.content}>
          <div className={styles.contacts}>
            <img src={logoImg} className={styles.logo} height="44" alt="" />

            <div className={styles.mainContacts}>
              <p className={styles.phone}>
                <a href={`tel:${CONTACT.phone}`} className="bp-link bp-accent">
                  {CONTACT.phone}
                </a>
              </p>
              <p className={styles.email}>
                <a href={`mailto:${CONTACT.email}`} className="bp-link bp-accent">
                  {CONTACT.email}
                </a>
              </p>
              <SocialLinks className={styles.social} linkClassName="bp-accent" />
            </div>

            <DonateButton className={styles.donateMobile} />

            <div className={styles.address}>
              <p>
                {companyTitle} {companyName}
              </p>
              <address>{CONTACT.address}</address>
              <p>
                {companyCode}
                :&nbsp;
                {CONTACT.companyCode}
              </p>
            </div>
            <p className={styles.copyright}>
              <LocalizedLink to="/privacy-policy" className={`bp-link ${styles.privacyLink}`}>
                {t.privacyPolicy}
              </LocalizedLink>
              <time dateTime={currentYear}>{currentYear}</time>
              &nbsp;©&nbsp;
              {companyName}
            </p>
          </div>

          <nav className={styles.navigation}>
            {NAVIGATION.map(({ key, children }) => (
              <NavigationColumn
                key={key}
                label={t[key]}
                links={children.map(child => ({
                  ...child,
                  label: t[child.key]
                }))}
              />
            ))}

            <DonateButton />
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
