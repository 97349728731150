import React from "react";
import PropTypes from "prop-types";

import { useTranslations } from "../hooks/translations";

import ButtonLink from "./button-link";

import styles from "./donate-button.module.scss";

const DonateButton = ({ className }) => {
  const t = useTranslations("navigation");

  return (
    <ButtonLink to="/donate" className={`${styles.button} ${className}`}>
      {t.donate}
    </ButtonLink>
  );
};

DonateButton.propTypes = {
  className: PropTypes.string
};

DonateButton.defaultProps = {
  className: ""
};

export default DonateButton;
