import ELECTION_DAY from "../enums/election-day.json";

// =====SECOND PHASE START=====

export const ADULT_DATE = "2024-05-01T00:00:00";

export const ELECTION_STAGES = [
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    start: "2024-05-07T00:00:00",
    end: "2024-05-09T00:00:00"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    start: "2024-05-08T00:00:00",
    end: "2024-05-10T00:00:00"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    start: "2024-05-10T00:00:00",
    end: "2024-05-11T00:00:00"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    date: "2024-05-12T00:00:00"
  }
];

// =====SECOND PHASE END=====
