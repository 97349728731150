import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { ELECTION_STAGES } from "../../../../content/calendar";
import TIME_UNIT from "../../../../enums/time-unit.json";

import IconArrow from "../../../assets/icons/arrow.svg";
import IconX from "../../../assets/icons/x.svg";

import { OutsideSSR } from "../../../ssr-context";
import { useTranslations } from "../../../hooks/translations";
import {
  getTimeUntilElectionDay,
  isElectionOver,
  isTodayElectionDay
} from "../../../utils/calendar";

import styles from "./calendar.module.scss";

const TIME_ORDER = [
  TIME_UNIT.MONTH,
  TIME_UNIT.DAY,
  TIME_UNIT.HOUR,
  TIME_UNIT.MINUTE,
  TIME_UNIT.SECOND
];

const removeZeroTimePadding = timeRemaining => {
  let timePartialIndex = -1;
  let timePartial;
  do {
    timePartialIndex += 1;
    timePartial = timeRemaining[TIME_ORDER[timePartialIndex]];
  } while (timePartial === 0);

  return TIME_ORDER.slice(timePartialIndex);
};

const getUnitTranslation = (value, t) => {
  if (value % 10 === 0 || (value > 10 && value < 20)) {
    return t.pluralAlternative;
  }
  if (value % 10 === 1) {
    return t.singular;
  }
  return t.plural;
};

const TimePartial = ({ value, translations }) => (
  <div className={styles.timePartial}>
    {/* eslint-disable react/no-array-index-key */}
    {`${value}`.split("").map((digit, index) => (
      <span key={`${digit}-${index}`} className={styles.value}>
        {digit}
      </span>
    ))}
    {/* eslint-enable react/no-array-index-key */}
    <span className={styles.label}>{getUnitTranslation(value, translations)}</span>
  </div>
);

TimePartial.propTypes = {
  value: PropTypes.number.isRequired,
  translations: PropTypes.shape({
    plural: PropTypes.string.isRequired,
    pluralAlternative: PropTypes.string.isRequired,
    singular: PropTypes.string.isRequired
  }).isRequired
};

const Calendar = ({ onCalendarToggle, className, hidden }) => {
  const t = useTranslations("calendar");

  const enums = useTranslations("enums");

  const [timeRemaining, setTimeRemaining] = useState(getTimeUntilElectionDay());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(getTimeUntilElectionDay());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const timeOrder = useMemo(() => removeZeroTimePadding(timeRemaining).slice(0, 3), [
    timeRemaining
  ]);

  const stages = useMemo(
    () =>
      ELECTION_STAGES.slice(0, -1).map(({ type, start, end }) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        return {
          type,
          startMonth: startDate.getMonth(),
          startDay: startDate.getDate(),
          endMonth: endDate.getMonth(),
          endDay: endDate.getDate()
        };
      }),
    []
  );

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className="bp-container">
        <button type="button" className={styles.closeMobile} onClick={onCalendarToggle}>
          {t.close} <IconX className="bp-stroke" />
        </button>
      </div>

      <div className={`bp-container ${styles.container}`}>
        {isTodayElectionDay() || isElectionOver() ? (
          <div className={styles.electionDay}>
            {/* =====SECOND PHASE START===== */}
            <p>Šiandien yra kovo 12 d. - Lietuvos Respublikos Prezidento rinkimų diena.</p>
            {/* =====SECOND PHASE END===== */}

            <p>Balsavimas vyksta nuo 7 iki 20 val.</p>

            <p>Skaidrių rinkimų!</p>
          </div>
        ) : (
          <>
            <div>
              <h1 className={styles.heading}>{t.heading}</h1>

              <table className={styles.table}>
                <thead>
                  <tr className="bp-visually-hidden">
                    <th>{t.stage}</th>
                    <th>{t.dates}</th>
                  </tr>
                </thead>
                <tbody>
                  {stages.map(({ type, startMonth, startDay, endDay }) => (
                    <tr key={type}>
                      <td className="bp-section-alternative">{enums.electionDay[type]}</td>
                      <td className="bp-section">
                        {enums.month[startMonth]} {startDay}-{endDay} {enums.timeUnit.DAY.short}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className={styles.learnMoreContainer}>
                <span id="learn-more">{t.learnMore}</span>
                <IconArrow className="bp-stroke" />
                <a
                  href="https://www.rinkejopuslapis.lt"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-labelledby="learn-more"
                  className="bp-link"
                  tabIndex={hidden ? -1 : null}
                >
                  www.rinkejopuslapis.lt
                </a>
              </div>
            </div>

            <div className={styles.remainingContainer}>
              <div className={styles.remaining}>
                <h2>{t.remaining}</h2>

                <OutsideSSR>
                  <div className={styles.clock}>
                    {timeOrder.map(unit => (
                      <TimePartial
                        key={unit}
                        value={timeRemaining[unit]}
                        translations={enums.timeUnit[unit]}
                      />
                    ))}
                  </div>
                </OutsideSSR>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Calendar.propTypes = {
  onCalendarToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  hidden: PropTypes.bool
};

Calendar.defaultProps = {
  className: "",
  hidden: false
};

export default Calendar;
